import "./productPage.css";
import React, { useState, useEffect } from "react";
import { AiOutlineSwap } from "react-icons/ai";
import currencyData from "../../Currency/currency.json";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog"; // 正确导入 Dialog 组件
import ForexChart from "../ForexChart/forexChart";
import { Card } from "primereact/card";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

export default function ProductPage() {
  let navigate = useNavigate();
  const loginUser = useSelector((state) => state.user.user.data);
  const [amountValue, setAmountValue] = useState(100);
  const [checkExch, setCheckExch] = useState(false);
  const [ifLogin, setIfLogin] = useState(false);
  const [currencyFrom, setCurrencyFrom] = useState(
    currencyData.find((e) => e.code === "USD")
  );
  const [currencyTo, setCurrencyTo] = useState(
    currencyData.find((e) => e.code === "CAD")
  );
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState("default");

  useEffect(() => {
    const isFirstVisit = !localStorage.getItem("visited");
    if (isFirstVisit) {
      setIsModalVisible(true);
      localStorage.setItem("visited", "true");
    }
    setIfLogin(!localStorage.getItem("user") ? true : false);
  }, []);

  const handleYes = () => {
    setIsModalVisible(false);
  };

  const handleNo = () => {
    setModalContent("alternative");
  };

  const valAmountEvent = (e) => {
    setAmountValue(e.value);
    setCheckExch(false);
  };

  const setFromCurrency = (e) => {
    setCheckExch(false);
    setCurrencyFrom(e.value);
  };

  const setTourrency = (e) => {
    setCheckExch(false);
    setCurrencyTo(e.value);
  };

  const switchCurrency = () => {
    const tempVal = { ...currencyTo };
    setCurrencyTo({ ...currencyFrom });
    setCurrencyFrom(tempVal);
  };

  const postOrder = () => {
    navigate("/forexsetup");
  };

  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <img alt={option.code} src={option.flag} className="flagImg" />
          <div>{option.code}</div>
        </div>
      );
    }
    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <img className="flagImg" src={option.flag} />
        <div>{option.code}</div>
      </div>
    );
  };

  return (
    <>
      <Dialog
        header="Disclaimer"
        visible={isModalVisible}
        onHide={() => setIsModalVisible(false)}
        style={{ width: "640px", padding: "40px" }}
        closable={false}
      >
        {modalContent === "default" ? (
          <>
            <p>
              This website is a demo, intended for investor and incubator
              reference only. All features are for demonstration purposes only
              and have not undergone any testing. The site is not open for
              public use. If you are not an investor or part of the incubator,
              please exit the site immediately.
            </p>
            <br />
            <p>
              We do not assume any liability for any direct, indirect,
              incidental, or consequential damages arising from the use of this
              website. Users assume all risks associated with accessing and
              using this site. Any reliance on the content of this website is at
              the user's own risk, and we make no representations or warranties
              regarding the accuracy, completeness, or reliability of such
              content.
            </p>
            <br />
            <p>Thank you for your understanding and support.</p>
          </>
        ) : (
          <>
            <p>You can contact us via email if there is any question.</p>
            <br />
            <p>Our email: miraclepaceinc@outlook.com</p>
            <br />
            <p>You may leave the website now.</p>
          </>
        )}
        <br />
        <div className="inputContainer">
          <div className="item">
            {modalContent === "default" && (
              <>
                <Button label="I Agree" onClick={handleYes} />
                <Button
                  label="Not Agree"
                  onClick={handleNo}
                  className="p-button-secondary"
                />
              </>
            )}
          </div>
        </div>
      </Dialog>

      <br />
      <br />
      <br />
      <Card className="wrapCard">
        <div className="pageWrapProduct">
          <div className="inputContainerExchange">
            <div className="item">
              <div>
                <p>From&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                <Dropdown
                  value={currencyFrom}
                  onChange={setFromCurrency}
                  options={currencyData}
                  optionLabel="name"
                  className="selectorCurr"
                  filter
                  filterBy="code"
                  valueTemplate={selectedCountryTemplate}
                  itemTemplate={countryOptionTemplate}
                />
              </div>
            </div>
            <div className="item iconWrap">
              <AiOutlineSwap id="swapIcon" onClick={switchCurrency} />
            </div>
            <div className="item">
              <div>
                <p>
                  To&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </p>
                <Dropdown
                  value={currencyTo}
                  onChange={setTourrency}
                  options={currencyData}
                  optionLabel="name"
                  className="selectorCurr"
                  filter
                  filterBy="code"
                  valueTemplate={selectedCountryTemplate}
                  itemTemplate={countryOptionTemplate}
                />
              </div>
            </div>
          </div>
          <br />
          <div id="forexChart">
            <ForexChart pair={currencyFrom.code + currencyTo.code} />
          </div>
          <br />
          <br />
          <div className="inputContainer">
            <div className="item">
              {loginUser ? (
                <Button
                  disabled={!amountValue}
                  label="Sell Currency"
                  onClick={postOrder}
                />
              ) : (
                <Button
                  disabled
                  label="Login Before Sell"
                  onClick={postOrder}
                />
              )}
              <Button
                disabled={!amountValue}
                label="Buy Currency"
                onClick={() => navigate("/buypage")}
              />
            </div>
          </div>
        </div>
      </Card>
    </>
  );
}
