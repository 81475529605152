import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { Widget } from "@uploadcare/react-widget";
import axios from "axios";
import { Toast } from "primereact/toast";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import "./idCheck.css";

export default function IDCheck() {
  let navigate = useNavigate();
  const [imageId, setImageId] = useState(null);
  const toast = useRef(null);

  const handleUploadChange = (fileInfo) => {
    console.log("Full File Info:", fileInfo);
    const uploadedImageId = fileInfo.uuid;
    console.log("Uploaded Image ID:", uploadedImageId);
    setImageId(uploadedImageId);
  };

  const submitImageId = async () => {
    if (!imageId) {
      toast.current.show({
        severity: "error",
        summary: "Upload Error",
        detail: "Please upload an image first.",
      });
      return;
    }

    try {
      const response = await axios.get(
        "https://miracleapi.com/user/postImage",
        {
          params: { imageId },
        }
      );

      console.log("Response from server:", response);
      toast.current.show({
        severity: "success",
        summary: "Upload Success",
        detail: `Successfully posted image ID: ${imageId}`,
      });
    } catch (error) {
      console.error("Failed to post image ID", error);
      toast.current.show({
        severity: "error",
        summary: "Upload Error",
        detail: "Failed to post image ID to the server.",
      });
    }
  };

  const backMain = () => {
    navigate("/");
  };

  console.log(
    "Uploadcare Public Key:",
    process.env.REACT_APP_UPLOADCARE_PUB_KEY
  );

  return (
    <div
      className="pageWrap flex flex-row gap2"
      style={{ display: "flex", justifyContent: "center" }}
    >
      <div>
        <Button onClick={backMain} text>
          <BiArrowBack />{" "}
        </Button>
        <br />

        <div className="idwrap">
          <h3>Buyer ID Checks:</h3>
          <br />
          <span>Please upload your ID.</span>
          <br />
          <Widget
            publicKey={process.env.REACT_APP_UPLOADCARE_PUB_KEY}
            multiple={false}
            imagesOnly
            onChange={handleUploadChange}
            tabs="file url"
            previewStep
          />
        </div>

        <div>
          <Button
            onClick={submitImageId}
            disabled={!imageId}
            className="submitBtn"
          >
            Submit Image ID
          </Button>
        </div>
        <Toast ref={toast} />
      </div>
    </div>
  );
}
