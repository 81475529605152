import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "primereact/button";
import { BiArrowBack } from "react-icons/bi";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { useNavigate } from "react-router-dom";
import ForexSetupModal from "./forexSetupModal";
import ForexSetupRow from "./ForexSetupRow";
import {
  fetchMyOrders,
  postNewOrder,
  deleteOrder,
  updateOrder,
} from "../../Redux/Action/orderAction";
import "./forexsetup.css";

export default function ForexSetup() {
  const loginUser = useSelector((state) => state.user.user.data);
  const myOrders = useSelector((state) => state.orders.myOrders || []);
  const dispatch = useDispatch();
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const setupModelRef = useRef();
  let navigate = useNavigate();

  useEffect(() => {
    if (loginUser) {
      dispatch(fetchMyOrders(loginUser.id));
    }
  }, [loginUser, dispatch]);

  const backMain = () => {
    navigate("/");
  };

  const editMyOrder = (data) => {
    setSelectedOrder(data);
    setModalVisible(true);
  };

  const newOrderModal = () => {
    setSelectedOrder(null);
    setModalVisible(true);
  };

  const confirmDel = (data) => {
    confirmDialog({
      message: "Do you want to delete this record?",
      header: "Delete Confirmation",
      icon: "pi pi-info-circle",
      defaultFocus: "reject",
      acceptClassName: "p-button-danger",
      accept: () =>
        dispatch(deleteOrder(data.id)).then(() =>
          dispatch(fetchMyOrders(loginUser.id))
        ),
    });
  };

  const handlePostNewOrder = (orderData) => {
    dispatch(postNewOrder(orderData, loginUser.id, loginUser.email)).then(
      () => {
        dispatch(fetchMyOrders(loginUser.id));
        setModalVisible(false);
      }
    );
  };

  const handleUpdateOrder = (orderData) => {
    dispatch(updateOrder(orderData, loginUser.id)).then(() => {
      dispatch(fetchMyOrders(loginUser.id));
      setModalVisible(false);
    });
  };

  // console.log("forexsteup: ", loginUser);
  return (
    <div className="setupForexWrap">
      <ConfirmDialog />
      <div className="button-group">
        <Button className="backBtn" onClick={() => backMain()} text>
          <BiArrowBack />{" "}
        </Button>
        <h1>All My Posts</h1>
        <div>
          {loginUser &&
          loginUser.currency.from.length &&
          loginUser.currency.to.length ? (
            <>
              <Button
                className="addBtnSetup"
                text
                label="Post A New Order"
                icon="pi pi-plus"
                onClick={() => newOrderModal()}
              ></Button>
              <Button
                className="refreshBtn"
                icon="pi pi-refresh"
                onClick={() => dispatch(fetchMyOrders(loginUser.id))}
              ></Button>
            </>
          ) : (
            <>
              <Button
                className="addBtnSetup"
                text
                label="Add banking info before trade"
                icon="pi pi-plus"
                onClick={() => navigate("/profile")}
              ></Button>
              <Button
                className="refreshBtn"
                icon="pi pi-refresh"
                onClick={() => dispatch(fetchMyOrders(loginUser.id))}
              ></Button>
            </>
          )}
        </div>
      </div>
      <div className="custom-table-container">
        <div className="custom-table-header">
          <div>Selling for Buying</div>
          <div>Exchange Rate</div>
          <div>Traded/Total Amount</div>
          <div>Edit</div>
          <div>Cancel</div>
        </div>
        {(myOrders || []).map((myOrder) => (
          <ForexSetupRow
            key={myOrder.id}
            myOrder={myOrder}
            editMyOrder={editMyOrder}
            confirmDel={confirmDel}
          />
        ))}
      </div>
      <ForexSetupModal
        ref={setupModelRef}
        visible={modalVisible}
        setVisible={setModalVisible}
        orderData={selectedOrder}
        onSubmit={selectedOrder ? handleUpdateOrder : handlePostNewOrder}
        loginUser={loginUser}
      />
    </div>
  );
}
